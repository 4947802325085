export const useTokenDecoder = (token) => {
  if (!token || !/^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/g.test(token)) {
    return null
  }

  const decodeData = (token) => Object.fromEntries(new URLSearchParams(atob(token)))

  let decodedData = decodeData(token)
  if (decodedData.isViewOnly) {
    token = decodedData['token']
    decodedData = { ...decodeData(token), isViewOnly: true, token: token }
  }

  if (
    !('con_case_ref' in decodedData) &&
    !('uuid' in decodedData) &&
    !('qc_case_ref' in decodedData) &&
    !('caseRef' in decodedData) &&
    !('employeeRef' in decodedData) &&
    !('employee_ref' in decodedData)
  ) {
    return null
  }

  return decodedData
}
